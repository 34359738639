<template>
	<span>
		<span @click="toggle">
			<slot name="reference" />
		</span>

		<a-modal v-if="isShow" :visible="true" title="Details" width="1000px" @cancel="toggle" :footer="null">
			<a-descriptions bordered :column="2" size="small">
				<a-descriptions-item :label="$t('table.Payee-Bank-Institution')"> {{ getBankInfo(itemData.payeeBankCode).name }} </a-descriptions-item>
				<a-descriptions-item :label="$t('table.created-at')"> {{ itemData.createTime }} </a-descriptions-item>
				<a-descriptions-item :label="$t('table.Payee-Name')"> {{ itemData.disburseName }} </a-descriptions-item>
				<a-descriptions-item :label="$t('table.created-by')"> {{ itemData.creatorName }} </a-descriptions-item>
				<a-descriptions-item :label="$t('table.Payee-Account-Number')"> {{ itemData.disburseAccount }} </a-descriptions-item>
				<a-descriptions-item :label="$t('table.reviewed-at')"> {{ itemData.reviewTime }} </a-descriptions-item>
				<a-descriptions-item :label="$t('table.Disbursement-Amount')"> {{ itemData.disburseAmount }} </a-descriptions-item>
				<a-descriptions-item :label="$t('table.reviewed-by')"> {{ itemData.reviewUserName }} </a-descriptions-item>
				<a-descriptions-item :label="$t('table.Message-to-Beneficiary')"> {{ itemData.messageToBeneficiary }} </a-descriptions-item>
				<a-descriptions-item :label="$t('table.completed-by')"> {{ itemData.completeTime }} </a-descriptions-item>
				<a-descriptions-item :label="$t('table.remark')"> {{ itemData.remark }} </a-descriptions-item>
				<a-descriptions-item :label="$t('table.status')"> {{ $t('enumerate.disburse-status' + `.${itemData.status}`) }} </a-descriptions-item>
			</a-descriptions>
		</a-modal>
	</span>
</template>

<script>
import { mapGetters } from 'vuex'
import { getBankInfo } from '@/utils'

export default {
	props: {
		itemData: {
			type: Object
		}
	},
	data() {
		return {
			isShow: false
		}
	},
	computed: {
		...mapGetters(['enumerate'])
	},
	methods: {
		toggle() {
			this.isShow = !this.isShow
			if (this.isShow) {
			}
		},
		getBankInfo,
	}
}
</script>
<style lang="less" scope></style>
