<template>
	<div>
		<span class="row-start-center">
			<span style="font-size: 18px; margin-right: 10px">Choice Bank</span>
			<img class="img-bank" src="@/assets/choicebank.png" />
		</span>
		<div style="margin: 24px 0 14px 0">
			<a-button type="primary" :disabled="$route.query.disburseType !== 'choice' || !hasMakerPermission()" @click="toDisburseNow">
				New Disbursement
			</a-button>
			<a-button type="primary" style="margin-left: 10px" :disabled="!hasMakerPermission()" @click="toRecordDisbursementEntries">
				Record Disbursement Records
			</a-button>
		</div>
		<a-table
			:columns="draftColumns"
			:data-source="tableData.reviewInfos"
			:row-key="(row, index) => row.id"
			bordered
			:pagination="false"
			:row-selection="{
				selectedRowKeys: selectedRecordIds,
				onChange: onSelectedRecordChange,
				getCheckboxProps: getCheckboxProps
			}"
		>
			<span slot="disburseAmount" slot-scope="data">
				<a-statistic :precision="2" :value="data.disburseAmount" />
			</span>

			<span slot="action" slot-scope="data">
				<disbursement-record-detail-modal :itemData="data">
					<a-button slot="reference" type="link"> Details </a-button>
				</disbursement-record-detail-modal>
			</span>
		</a-table>
		<div style="margin-top: 24px; float: right">
			<a-popconfirm
				:ok-text="$t('enumerate.yes-no.1')"
				:cancel-text="$t('enumerate.yes-no.0')"
				:title="$t('page.confirm')"
				:disabled="selectedRecordIds.length === 0 || !hasCheckerPermission()"
				@confirm="reviewRecord(true)"
			>
				<a-button
					type="primary"
					:disabled="selectedRecordIds.length === 0 || !hasCheckerPermission()"
					:class="{ 'btn-approve': selectedRecordIds.length > 0 && hasCheckerPermission() }"
				>
					Approve
				</a-button>
			</a-popconfirm>

			<a-popconfirm
				:ok-text="$t('enumerate.yes-no.1')"
				:cancel-text="$t('enumerate.yes-no.0')"
				:title="$t('page.confirm')"
				:disabled="selectedRecordIds.length === 0 || !hasCheckerPermission()"
				@confirm="reviewRecord(false)"
			>
				<a-button
					type="primary"
					:disabled="selectedRecordIds.length === 0 || !hasCheckerPermission()"
					:class="{ 'btn-reject': selectedRecordIds.length > 0 && hasCheckerPermission() }"
					style="margin-left: 10px"
				>
					Reject
				</a-button>
			</a-popconfirm>
		</div>
	</div>
</template>

<script>
import { getDisbursedReviewList, reviewDisbursedRecords } from '@/api/loan'
import { getBankInfo } from '@/utils'
import DisbursementRecordDetailModal from './components/disbursement-record-detail-modal.vue'

export default {
	components: {
		DisbursementRecordDetailModal
	},
	data() {
		return {
			tableData: {},
			selectedRecordIds: []
		}
	},
	computed: {
		draftColumns() {
			return [
				{
					title: 'No.',
					align: 'center',
					customRender: (row, row2, index) => index + 1
				},
				{
					title: this.$t('table.Payee-Bank-Institution'),
					align: 'center',
					customRender: (row) => getBankInfo(row.payeeBankCode).name
				},
				{
					title: this.$t('table.Payee-Name'),
					dataIndex: 'disburseName',
					align: 'center'
				},
				{
					title: this.$t('table.Payee-Account-Number'),
					dataIndex: 'disburseAccount',
					align: 'center'
				},
				{
					title: this.$t('table.Disbursement-Amount'),
					scopedSlots: { customRender: 'disburseAmount' },
					align: 'center'
				},
				{
					title: this.$t('table.created-at'),
					dataIndex: 'createTime',
					align: 'center'
				},
				{
					title: this.$t('table.Status'),
					align: 'center',
					customRender: (row) => this.$t('enumerate.disburse-status' + `.${row.status}`)
				},
				{
					title: this.$t('table.details'),
					scopedSlots: { customRender: 'action' },
					align: 'center'
				}
			]
		}
	},
	mounted() {
		this.fetchDisburseInfoData()
	},
	beforeDestroy() {
		if (this.timer) clearTimeout(this.timer)
	},
	methods: {
		async fetchDisburseInfoData() {
			const params = {
				loanId: this.$route.query.loanId,
				loanType: this.$route.query.loanType,
				disburseLoanType: this.$route.query.disburseLoanType
			}
			this.tableData = await getDisbursedReviewList(params)
		},
		toDisburseNow() {
			this.$router.push({
				path: 'disburseNow',
				query: {
					loanId: this.$route.query.loanId,
					loanType: this.$route.query.loanType,
					disburseLoanType: this.$route.query.disburseLoanType
				}
			})
		},
		toRecordDisbursementEntries() {
			this.$router.push({
				path: 'recordDisbursementEntries',
				query: {
					loanId: this.$route.query.loanId,
					loanType: this.$route.query.loanType,
					disburseLoanType: Number(this.$route.query.disburseLoanType),
					disburseType: this.$route.query.disburseType
				}
			})
		},
		onSelectedRecordChange(keys, rows) {
			this.selectedRecordIds = keys
		},
		getCheckboxProps(record) {
			return {
				props: {
					disabled: record.status !== 11 || !this.hasCheckerPermission(),
					name: record.id
				}
			}
		},
		async reviewRecord(isPass) {
			const params = {
				version: this.tableData.version,
				ids: this.selectedRecordIds,
				loanId: this.$route.query.loanId,
				loanType: this.$route.query.loanType,
				disburseLoanType: Number(this.$route.query.disburseLoanType),
				pass: isPass
			}
			this.tableData = await reviewDisbursedRecords(params)
			this.selectedRecordIds = []
			this.$message.success('Success')
			this.timer = setTimeout(() => {
				this.fetchDisburseInfoData()
			}, 2000)
		},
		hasMakerPermission() {
			switch (this.$route.name) {
				case 'menus.loanDataMenu.loanApplicationDisbursementRecords':
				case 'menus.loanDataMenu.ipfDisbursementRecords':
					return this.$hasPermission('ROLE_PENGING_LOAN_MAKER')
				case 'menus.insuranceRenewalMenu.loanApplicationDisbursementRecords':
				case 'menus.insuranceRenewalMenu.ipfDisbursementRecords':
					return this.$hasPermission('ROLE_RENEWAL_PENDING_MAKER')
				case 'menus.emergencyLoanMenu.loanApplicationDisbursementRecords':
				case 'menus.emergencyLoanMenu.ipfDisbursementRecords':
					return this.$hasPermission('ROLE_EMERGENCY_DISBURSE_WAITING_MAKER')
				default:
					return true
			}
		},
		hasCheckerPermission() {
			switch (this.$route.name) {
				case 'menus.loanDataMenu.loanApplicationDisbursementRecords':
				case 'menus.loanDataMenu.ipfDisbursementRecords':
					return this.$hasPermission('ROLE_PENGING_LOAN_CHECKER')
				case 'menus.insuranceRenewalMenu.loanApplicationDisbursementRecords':
				case 'menus.insuranceRenewalMenu.ipfDisbursementRecords':
					return this.$hasPermission('ROLE_RENEWAL_PENDING_CHECKER')
				case 'menus.emergencyLoanMenu.loanApplicationDisbursementRecords':
				case 'menus.emergencyLoanMenu.ipfDisbursementRecords':
					return this.$hasPermission('ROLE_EMERGENCY_DISBURSE_WAITING_CHECKER')
				default:
					return true
			}
		}
	}
}
</script>

<style lang="less" scoped>
.img-bank {
	height: 48px;
	border: 1px solid #e8e8e8;
	border-radius: 24px;
	padding: 0px 24px;
}

.btn-approve {
	background-color: #67c23a;
	border-color: #67c23a;
}

.btn-reject {
	background-color: #e6a23c;
	border-color: #e6a23c;
}

/deep/ .ant-statistic-content-value-int,
/deep/ .ant-statistic-content-value-decimal {
	font-size: 14px;
	color: #000000a6;
}
</style>
